<template>
<div>
    <div class="display-block">
        <alert-message v-if="msg" :error="msg"></alert-message>
        <page-header v-model="search" title="Staffs" permission="createStaff" @input="updateApiCall(apiCall,search)" @click="navigateTo('/app/staffs/0')"></page-header>
    </div>
    <div>
        <v-data-table :loading="loading" :page="currentPage" @update:page="setCurrentPage($event,lsCurrentPageKey)" 
        :headers="headers" :items="items" class="elevation-1" :search="search" :items-per-page="items.length" hide-default-footer>
            <template v-slot:item.action="{ item }" v-if="$vuetify.breakpoint.smAndUp">
                <template v-for="icon in icons">
                    <action-button :key="icon.name" :icon="icon.name" :show="isAllowed(icon.permission)" @click="icon.name=='mdi-delete'?deleteStaff(item._id):performAction(icon,item._id)"></action-button>
                </template>
            </template>
            <template v-slot:item="{ item }" v-if="$vuetify.breakpoint.xsOnly">
                <staff-card :id="item._id" :name="item.firstName+' '+item.lastName" :mobileNo="item.mobileNo" :email="item.email" :roles="item.roles" :icons="icons" @delete="removeItem"></staff-card>
            </template>
        </v-data-table>
        <pagination :api="apiCall" ref="pagination" v-model="items" sort="+firstName"></pagination>
    </div>
    <alert-message v-if="msg" :error="msg"></alert-message>
</div>
</template>

<script>
import ActionButton from '@/components/ActionButton'
import appConstants from '@/utils/appConstants'
import StaffCard from '@/components/UserResponsiveCard'
export default {
    components: {
        ActionButton,
        StaffCard
    },
    data() {
        return {
            search: "",
            headers: [{
                    text: "FirstName",
                    value: "firstName"
                },
                {
                    text: "MiddleName",
                    value: "middleName"
                },
                {
                    text: 'LastName',
                    value: 'lastName'
                },
                {
                    text: 'Mobile no',
                    value: 'mobileNo'
                },
                {
                    text: 'E-Mail',
                    value: 'email'
                },
                {
                    text: 'Actions',
                    value: 'action',
                    align: 'right'
                }
            ],
            search: '',
            items: [],
            icons: [{
                    name: "mdi-eye",
                    path: "/app/staffs/view/",
                    permission: "viewStaff"
                }, {
                    name: "mdi-pencil",
                    path: "/app/staffs/",
                    permission: "editStaff"
                },
                {
                    name: "mdi-delete",
                    permission: "deleteStaff"
                },
                {
                    name: "mdi-update",
                    path: "/app/staffs/resetStaffPassword/",
                    permission: "resetStaffPassword"
                },
                {
                    name: "mdi-lock-reset",
                    permission: "forceResetPassword"
                }
            ],
            lsCurrentPageKey: 'staffs-current-page',
            loading: false,
            apiCall:appConstants.STAFFS_API+'?userType=STAFF'
        };
    },
    methods: {
        async deleteStaff(id) {
            try {
                await this.deleteItem("Are you sure you want to delete this Staff Member?", appConstants.STAFFS_API + "/" + id)
                this.items.splice(this.items.findIndex(rec => rec._id == id), 1)
            } catch (error) {
                this.handleError(error)
                this.msg = error.response.data
            }
        },
        forceReset() {
            return this.$router.push({ name: "resetStaffPassword", params: { show: false } })
        },
        removeItem(id) {
            this.deleteStaff(id)
        }
    }
};
</script>
